<template>
    <TablePlaceholder :data="CRM">
        <template #default="{ items: _items }">
            <TilesTable
                :columns="4"
                :is-loading="isLoading.getCrm"
                :items="_items">
                <template #default="{ item }">
                    <CRMTableItem
                        :item="item"
                        :is-loading="isLoading.deleteCrm"
                        @delete="deleteCRM($event)"
                        @edit="selectCRM($event)">
                        <template #content>
                            &#8203;
                        </template>
                    </CRMTableItem>
                </template>
            </TilesTable>
            <InfiniteLoading
                v-if="isInfinityLoading"
                @infinite="infiniteHandler">
                <div slot="spinner"></div>
            </InfiniteLoading>
        </template>
        <template #placeholder>
            {{ $t(`common.table.noData`) }}
        </template>
    </TablePlaceholder>
</template>

<script>
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
  import TilesTable from "@/components/Common/TilesTable";
  import {
    GET_CRM,
    DELETE_CRM,
    GET_CRM_BY_ALIAS,
    UPDATE_MODAL_ACTIVE,
    UPDATE_CRM_PAGINATION
  } from "@core/store/action-constants";
  import { UPDATE_MODAL_IS_EDIT } from "@core/store/mutation-constants";
  import { mapActions, mapMutations, mapState } from "vuex";
  import CRMTableItem from "@/components/Admin/CRM/CRMTableItem";
  import InfiniteLoading from "vue-infinite-loading";
  import { actionWithToast } from "@/helpers/actionWithToast";
  
  export default {
    name: "CRMTable",
    components: {
      CRMTableItem,
      TilesTable,
      TablePlaceholder,
      InfiniteLoading
    },
    created () {
      this.GET_CRM();
    },

    computed: {
      ...mapState("admin/crm", {
        CRM: (state) => state.CRM,
        pagination: (state) => state.pagination
      }),

      isLoading () {
        return {
          getCrm: this.$wait(`admin/crm/${ GET_CRM }`),
          deleteCrm: this.$wait(`admin/crm/${ DELETE_CRM }`)
        };
      },
      
      isInfinityLoading () {
        const { perPage, page } = this.pagination;
        return this.CRM?.count > perPage * page;
      }
    },

    methods: {
      ...mapMutations("admin/crm/crmCreateModal", {
        UPDATE_MODAL_IS_EDIT
      }),

      ...mapActions("admin/crm", {
        GET_CRM,
        UPDATE_CRM_PAGINATION,
        GET_CRM_BY_ALIAS,
        DELETE_CRM,
        UPDATE_MODAL_ACTIVE: `crmCreateModal/${ UPDATE_MODAL_ACTIVE }`
      }),
  
      async infiniteHandler ($state) {
        this.UPDATE_CRM_PAGINATION({ page: this.pagination.page + 1 });
        await this.GET_CRM();
        $state.loaded();
      },

      selectCRM (alias) {
        this.UPDATE_MODAL_ACTIVE(true);
        this.UPDATE_MODAL_IS_EDIT(true);
        this.GET_CRM_BY_ALIAS(alias);
      },

      deleteCRM (alias) {
        this.$buefy.dialog.confirm({
          title: this.$t("admin.crm.table.removeCrmModal.title"),
          message: this.$t("admin.crm.table.removeCrmModal.confirm"),
          confirmText: this.$t("common.buttons.delete"),
          cancelText: this.$t("common.buttons.cancel"),
          type: "is-danger is-light",
          onConfirm: async () => {
            await actionWithToast(
              this.DELETE_CRM(alias),
              "admin.crm.table.removeCrmModal.messages.deleteCRM"
            );
          }
        });
      }
    }
  };
</script>

<style scoped>

</style>
