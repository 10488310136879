<template>
    <CustomCollapse open>
        <template #title>
            <h5 class="subtitle is-4 my-2 card-header-title p-0">
                {{ $t("admin.crm.modal.labels.integrationDetail.response") }}
            </h5>
        </template>

        <template #icon="{ trigger }">
            <a class="card-header-icon my-2 py-2 pl-4 pr-0">
                <b-icon :icon="!trigger.open ? 'angle-down' : 'angle-up'"></b-icon>
            </a>
        </template>

        <template #content>
            <div class="columns mb-0">
                <div class="column">
                    <b-field :label="$t('admin.crm.modal.labels.integrationDetail.responseType')">
                        <b-radio-button
                            :key="type.value"
                            v-for="type in responseTypesList.items"
                            v-model="responseType"
                            :disabled="isWithoutRequest"
                            :native-value="type.value"
                            type="is-info is-light">
                            <span v-t="type.label"></span>
                        </b-radio-button>
                    </b-field>
                </div>

                <div class="column">
                    <b-field :label="$t('admin.crm.modal.labels.integrationDetail.responseXpath')">
                        <LInput
                            v-model="responseXpath"
                            :disabled="isWithoutRequest">
                        </LInput>
                    </b-field>
                </div>
            </div>
        </template>
    </CustomCollapse>
</template>

<script>
  import CustomCollapse from "@/components/Common/Controls/CustomCollapse.vue";
  import { CRM } from "@core/mixins";
  import { UPDATE_INTEGRATION_DETAILS } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapState } from "vuex";
  
  export default {
    name: "CRMCreateResponse",
    mixins: [CRM],
    components: {
      CustomCollapse
    },
    
    computed: {
      ...mapState("admin/crm/crmCreateModal", {
        responseTypesList: state => state.responseTypesList.data,
        isWithoutRequest: state => state.isWithoutRequest
      }),
      
      ...mapFields("admin/crm/crmCreateModal", {
        fields: ["responseType", "responseXpath"],
        base: "options.integrationDetail",
        action: UPDATE_INTEGRATION_DETAILS
      })
    }
  };
</script>

<style scoped>

</style>
