<template>
    <SectionWrapper
        :action-button-options="actionButtonOptions"
        @action="UPDATE_MODAL_ACTIVE(true)">
        <template #sticky>
            <span></span>
        </template>
        <CRMTable class="mt-3"></CRMTable>

        <CRMCreateModal></CRMCreateModal>
        <CRMFailRuleDataModal></CRMFailRuleDataModal>
    </SectionWrapper>
</template>

<script>
  import CRMFailRuleDataModal from "@/components/Admin/CRM/CRMCreateModal/CRMFailRule/CRMFailRuleDataModal.vue";
  import CRMCreateModal from "@/components/Admin/CRM/CRMCreateModal/CRMCreateModal";
  import SectionWrapper from "@/components/Common/SectionWrapper";
  import CRMTable from "@/components/Admin/CRM/CRMTable";
  import { mapActions } from "vuex";
  import { UPDATE_MODAL_ACTIVE } from "@core/store/action-constants";
  import { SET_EMPTY } from "@core/store/action-constants";

  export default {
    name: "CRM",
    components: {
      CRMFailRuleDataModal,
      CRMCreateModal,
      SectionWrapper,
      CRMTable
    },

    computed: {
      actionButtonOptions () {
        return {
          label: this.$t("admin.crm.tooltip.addCRM"),
          permissions: ["CRM.MANAGE"]
        };
      }
    },

    methods: {
      ...mapActions("admin/crm", {
        UPDATE_MODAL_ACTIVE: `crmCreateModal/${ UPDATE_MODAL_ACTIVE }`,
        SET_EMPTY
      })
    },

    beforeDestroy () {
      this.SET_EMPTY();
    }
  };
</script>

<style scoped>

</style>