<template>
    <ModalBox
        :key="locale"
        :is-active="isCRMCreateModalActive"
        :is-loading="isLoading"
        :on-cancel="() => closeModalConfirm()"
        @submit.stop.prevent="submit">
        <template #header>
            <h5 class="title is-5">
                {{ $t(`admin.crm.modal.labels.${ isEdit ? 'editingCrm' : 'creatureCrm' }`) }}
            </h5>
        </template>

        <b-field :label="$t('admin.crm.modal.labels.name')">
            <LInput
                v-model="name"
                required>
            </LInput>
        </b-field>

        <b-field
            v-show="!isEdit"
            :label="$t('admin.crm.modal.labels.alias')">
            <LInput
                v-model="alias"
                pattern="[a-z]+"
                required>
            </LInput>
        </b-field>


        <CRMFailRule>
        </CRMFailRule>

        <b-checkbox
            v-model="isWithoutRequest"
            class="my-3"
            style="width: 100%">
            {{ $t("admin.crm.modal.labels.withoutRequest") }}
        </b-checkbox>

        <b-checkbox
            v-model="requireRemoteId"
            :disabled="isWithoutRequest"
            class="my-3">
            {{ $t("admin.crm.modal.labels.requireRemoteId") }}
        </b-checkbox>

        <CRMCreateRequest ref="request">
        </CRMCreateRequest>

        <CRMCreateResponse ref="response">
        </CRMCreateResponse>

        <CRMCreateFlowRedirect ref="flowRedirect">
        </CRMCreateFlowRedirect>

        <CRMCreatePostbackConfiguration ref="postbackConfiguration">
        </CRMCreatePostbackConfiguration>

        <CRMCreateRecoveryDetail>
        </CRMCreateRecoveryDetail>

        <CRMCustomMacros class="mt-4">
        </CRMCustomMacros>

        <template #footer>
            <b-button
                :disabled="isLoading"
                class="is-fullwidth is-info is-light"
                native-type="submit">
                {{ $t(`admin.finances.costs.modal.footer.confirm`) }}
            </b-button>
        </template>
    </ModalBox>
</template>

<script>
  import {
    CREATE_CRM,
    EDIT_CRM,
    GET_CRM_BY_ALIAS,
    GET_FLOW_REDIRECT_MACROS,
    GET_MACROS,
    GET_METHODS,
    GET_REQUEST_TYPES,
    GET_RESPONSE_TYPES,
    GET_TRANSFER_RULES_URL,
    GET_STRATEGY,
    PROXY_CRM,
    UPDATE_IS_WITHOUT_REQUEST,
    UPDATE_MODAL_ACTIVE,
    UPDATE_CRM_PAGINATION, SET_EMPTY
  } from "@core/store/action-constants";
  import { UPDATE_INTEGRATION_DETAILS, UPDATE_MODAL_OPTIONS } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapState } from "vuex";
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import CRMCreateFlowRedirect from "@/components/Admin/CRM/CRMCreateModal/CRMCreateFlowRedirect";
  import CRMCreatePostbackConfiguration from "@/components/Admin/CRM/CRMCreateModal/CRMCreatePostbackConfiguration";
  import CRMCreateRecoveryDetail from "@/components/Admin/CRM/CRMCreateModal/CRMCreateRecoveryDetail";
  import CRMCreateRequest from "@/components/Admin/CRM/CRMCreateModal/CRMCreateRequest";
  import CRMCreateResponse from "@/components/Admin/CRM/CRMCreateModal/CRMCreateResponse";
  import CRMCustomMacros from "@/components/Admin/CRM/CRMCreateModal/CRMCustomMacros";
  import CRMFailRule from "@/components/Admin/CRM/CRMCreateModal/CRMFailRule/CRMFailRule.vue";
  import { actionWithToast } from "@/helpers/actionWithToast";

  export default {
    name: "CRMCreateModal",

    components: {
      ModalBox,
      CRMCreateRequest,
      CRMCreateResponse,
      CRMCustomMacros,
      CRMFailRule,
      CRMCreatePostbackConfiguration,
      CRMCreateRecoveryDetail,
      CRMCreateFlowRedirect
    },

    created () {
      this.GET_METHODS();
      this.GET_REQUEST_TYPES();
      this.GET_RESPONSE_TYPES();
      this.GET_RESPONSE_FORMAT_TYPES();
      this.GET_STRATEGY();
      this.GET_MACROS();
      this.GET_TRANSFER_RULES_URL();
      this.GET_FLOW_REDIRECT_MACROS();
    },

    computed: {
      ...mapState("admin/crm/crmCreateModal", {
        isCRMCreateModalActive: state => state.isCRMCreateModalActive,
        isEdit: state => state.isEdit,
        _isWithoutRequest: state => state.isWithoutRequest
      }),

      ...mapState("admin/crm/crmCreateModal/crmFailRule", {
        isModalActive: ({ isModalActive }) => isModalActive
      }),

      ...mapFields("admin/crm/crmCreateModal", {
        fields: ["alias", "name"],
        base: "options",
        action: UPDATE_MODAL_OPTIONS
      }),

      ...mapFields("admin/crm/crmCreateModal", {
        fields: ["requireRemoteId"],
        base: "options.integrationDetail",
        action: UPDATE_INTEGRATION_DETAILS
      }),

      isWithoutRequest: {
        get () {
          return this._isWithoutRequest;
        },
        set (value) {
          this.UPDATE_IS_WITHOUT_REQUEST(value);
        }
      },

      locale () {
        return this.$root.$i18n.locale;
      },

      isLoading () {
        return this.$wait(`admin/crm/crmCreateModal/${ CREATE_CRM }`)
          || this.$wait(`admin/crm/crmCreateModal/${ EDIT_CRM }`)
          || this.$wait(`admin/crm/${ GET_CRM_BY_ALIAS }`);
      }
    },

    methods: {
      ...mapActions("admin/crm/crmCreateModal", {
        PROXY_CRM,
        SET_EMPTY,
        UPDATE_MODAL_ACTIVE,
        UPDATE_IS_WITHOUT_REQUEST,
        GET_METHODS: `methodsList/${ GET_METHODS }`,
        GET_REQUEST_TYPES: `requestTypesList/${ GET_REQUEST_TYPES }`,
        GET_RESPONSE_TYPES: `responseTypesList/${ GET_RESPONSE_TYPES }`,
        GET_RESPONSE_FORMAT_TYPES: `responseFormatTypesList/${ GET_RESPONSE_TYPES }`,
        GET_STRATEGY: `strategyList/${ GET_STRATEGY }`,
        GET_MACROS: `macrosList/${ GET_MACROS }`,
        GET_TRANSFER_RULES_URL: `transferRulesUrlList/${ GET_TRANSFER_RULES_URL }`,
        GET_FLOW_REDIRECT_MACROS: `flowRedirectMacrosList/${ GET_FLOW_REDIRECT_MACROS }`
      }),

      ...mapActions("admin/crm", {
        UPDATE_CRM_PAGINATION
      }),

      closeModalConfirm () {
        this.$buefy.dialog.confirm({
          title: this.$t("common.entity.modal.confirmationToCancel.title"),
          message: this.$t("common.entity.modal.confirmationToCancel.message"),
          confirmText: this.$t("common.entity.modal.confirmationToCancel.confirmText"),
          cancelText: this.$t("common.entity.modal.confirmationToCancel.cancelText"),
          type: "is-danger is-light",
          onConfirm: () => {
            this.SET_EMPTY();
          }
        });
      },

      isValid () {
        return this.$refs.request.isValid()
          && this.$refs.flowRedirect.isValid()
          && this.$refs.postbackConfiguration.isValid();
      },

      async submit () {
        if (this.isValid()) {
          await actionWithToast(
            this.PROXY_CRM(),
            this.isEdit
              ? "admin.crm.modal.message.crmEdited"
              : "admin.crm.modal.message.crmCreating"
          );
          this.SET_EMPTY();
        } else {
          this.$buefy.toast.open({
            message: this.$t("admin.crm.modal.message.errors.notValidData"),
            type: "is-danger"
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .card-content {
            padding: 0;
        }
    }
</style>
