<template>
    <CustomCollapse open>
        <template #title>
            <h5 class="subtitle is-4 my-2 card-header-title p-0">
                {{ $t("admin.crm.modal.labels.recoveryDetail.statusConfiguration") }}
            </h5>
        </template>

        <template #icon="{ trigger }">
            <a class="card-header-icon my-2 py-2 pl-4 pr-0">
                <b-icon :icon="!trigger.open ? 'angle-down' : 'angle-up'"></b-icon>
            </a>
        </template>

        <template #content>
            <b-checkbox
                v-model="isAutoRequests"
                class="my-2">
                {{ $t("admin.crm.modal.labels.automaticStatusCheck") }}
            </b-checkbox>

            <b-checkbox
                v-model="isBulkRequests"
                :required="isRequired"
                class="my-2 mb-4">
                {{ $t("admin.crm.modal.labels.bulkStatusQuery") }}
            </b-checkbox>

            <b-field
                :label="$t('admin.crm.modal.labels.maxBulkRequests')"
                :message="isBulkRequests && !maxBulkRequests ? $t('admin.crm.modal.message.errors.emptyField') : ''"
                :type="isBulkRequests && !maxBulkRequests ? 'is-danger' : ''">
                <LInput
                    v-model.number="maxBulkRequests"
                    :disabled="!isBulkRequests"
                    type="number"
                    min="1"
                    max="1000"
                    :required="isRequired">
                </LInput>
            </b-field>

            <b-field
                label="URL"
                :message="isRequired && !requestUrl ? $t('admin.crm.modal.message.errors.emptyField') : ''"
                :type="isRequired && !requestUrl ? 'is-danger' : ''">
                <LInput v-model="requestUrl">
                    <template #default="{ value, update }">
                        <b-input
                            ref="requestUrl"
                            :value="value"
                            placeholder="https://"
                            :required="isRequired"
                            type="url"
                            @input="update">
                        </b-input>
                    </template>
                </LInput>
            </b-field>

            <b-taglist>
                <b-button
                    :key="macros"
                    v-for="macros in macrosList"
                    class="tag"
                    size="is-small"
                    type="is-info"
                    :required="isRequired"
                    @click="addMacro(`{${macros}}`)">
                    {{ "{" + macros + "}" }}
                </b-button>
            </b-taglist>

            <b-field
                :label="$t('admin.crm.modal.labels.integrationDetail.requestHeaders')"
                :message="!validationOptions.requestHeaders ? $t('admin.crm.modal.message.errors.notJsonRequestBody') : ''"
                :type="{'is-danger': !validationOptions.requestHeaders}">
                <LInput v-model="requestHeaders">
                    <template #default="{ value, update }">
                        <b-input
                            ref="requestHeaders"
                            :value="value"
                            type="textarea"
                            @input="update">
                        </b-input>
                    </template>
                </LInput>
            </b-field>

            <b-taglist>
                <b-button
                    :key="macros"
                    v-for="macros in macrosList"
                    class="tag"
                    size="is-small"
                    type="is-info"
                    @click="addMacro(`{${macros}}`, 'requestHeaders', 'textarea')">
                    {{ "{" + macros + "}" }}
                </b-button>
            </b-taglist>

            <b-field
                :label="$t('admin.crm.modal.labels.transferIdKey')"
                :message="isRequired && !transferIdKey ? $t('admin.crm.modal.message.errors.emptyField') : ''"
                :type="isRequired && !transferIdKey ? 'is-danger' : ''">
                <LInput
                    v-model="transferIdKey"
                    :required="isRequired">
                </LInput>
            </b-field>

            <div class="columns is-multiline">
                <div class="column">
                    <b-field
                        :label="$t('admin.crm.modal.labels.transferRules')"
                        :message="isRequired && !transferIdStrategy ? $t('admin.crm.modal.message.errors.ruleSelected') : ''"
                        :type="isRequired && !transferIdStrategy ? 'is-danger' : ''">
                        <b-radio-button
                            :key="transferRulesUrl.value"
                            v-for="transferRulesUrl in transferRulesUrlList.items"
                            v-model="transferIdStrategy"
                            :required="isRequired"
                            :native-value="transferRulesUrl.value"
                            name="transferIdStrategy"
                            type="is-info is-light">
                            {{ transferRulesUrl.label }}
                        </b-radio-button>
                    </b-field>
                </div>
                <div class="column">
                    <b-field
                        :label="$t('admin.crm.modal.labels.integrationDetail.responseType')"
                        :message="isRequired && !responseType ? $t('admin.crm.modal.message.errors.typeSelected') : ''"
                        :type="isRequired && !responseType ? 'is-danger' : ''">
                        <b-radio-button
                            :key="type.value"
                            v-for="type in responseTypesList.items"
                            v-model="responseType"
                            :required="isRequired"
                            :native-value="type.value"
                            name="responseType"
                            type="is-info is-light">
                            {{ type.label }}
                        </b-radio-button>
                    </b-field>
                </div>
            </div>

            <b-field
                :label="$t('admin.crm.modal.labels.responseXpath')"
                :message="isRequired && !responseXpath ? $t('admin.crm.modal.message.errors.emptyField') : ''"
                :type="isRequired && !responseXpath ? 'is-danger' : ''">
                <LInput
                    v-model.number="responseXpath"
                    :required="isRequired">
                </LInput>
            </b-field>
        </template>
    </CustomCollapse>
</template>

<script>
  import CustomCollapse from "@/components/Common/Controls/CustomCollapse.vue";
  import { GET_RECOVERY_STRATEGY, UPDATE_IS_AUTO_REQUESTS } from "@core/store/action-constants";
  import { UPDATE_CUSTOM_MACROS_RECOVERY_DETAIL, UPDATE_RECOVERY_DETAILS } from "@core/store/mutation-constants";
  import { hasEmptyObject } from "@core/helpers/hasEmptyObject";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapGetters, mapState } from "vuex";
  import { CRM } from "@core/mixins";

  export default {
    name: "CRMCreateRecoveryDetail",
    mixins: [CRM],
    components: {
      CustomCollapse
    },

    data () {
      return {
        validationOptions: {
          requestHeaders: true
        }
      };
    },

    computed: {
      ...mapState("admin/crm/crmCreateModal",{
        recoveryStrategyList: state => state.recoveryStrategyList.data,
        transferRulesUrlList: state => state.transferRulesUrlList.data,
        responseTypesList: state => state.responseFormatTypesList.data,
        recoveryDetail: state => state.options.recoveryDetail,
        _isAutoRequests: state => state.options.isAutoRequests,
        macrosList: state => state.macrosList.data
      }),

      ...mapGetters("admin/crm/crmCreateModal", {
        autoRequests: state => state.options.isAutoRequests
      }),

      ...mapFields("admin/crm/crmCreateModal", {
        fields: [
          "isBulkRequests",
          "maxBulkRequests",
          "transferIdKey",
          "transferIdStrategy",
          "responseXpath",
          "responseType"
        ],
        base: "options.recoveryDetail",
        action: UPDATE_RECOVERY_DETAILS
      }),

      ...mapFields("admin/crm/crmCreateModal", {
        fields: ["requestUrl", "requestHeaders"],
        base: "options.recoveryDetail",
        action: UPDATE_CUSTOM_MACROS_RECOVERY_DETAIL
      }),

      isAutoRequests: {
        get () {
          return this._isAutoRequests;
        },
        set (value) {
          this.UPDATE_IS_AUTO_REQUESTS(value);
        }
      },

      isRequired () {
        return !hasEmptyObject(this.recoveryDetail);
      }
    },

    methods: {
      ...mapActions("admin/crm/crmCreateModal", {
        GET_RECOVERY_STRATEGY: `recoveryStrategyList/${ GET_RECOVERY_STRATEGY }`,
        UPDATE_IS_AUTO_REQUESTS
      }),

      getRecoveryStrategy () {
        this.GET_RECOVERY_STRATEGY();
        return this.recoveryStrategyList;
      }
    },

    watch: {
      requestHeaders (value) {
        this.$set(this.validationOptions, "requestHeaders", this.isJsonString(value));
      }
    }
  };
</script>

<style scoped>

</style>
