<template>
    <CustomCollapse open>
        <template #title>
            <h5 class="subtitle is-4 my-2 card-header-title p-0">
                {{ $t("admin.crm.modal.labels.integrationDetail.flow") }}
            </h5>
        </template>
        
        <template #icon="{ trigger }">
            <a class="card-header-icon my-2 py-2 pl-4 pr-0">
                <b-icon :icon="!trigger.open ? 'angle-down' : 'angle-up'"></b-icon>
            </a>
        </template>
        
        <template #content>
            <b-field
                :label="$t('admin.crm.modal.labels.integrationDetail.flowRedirect')"
                :message="!validationOptions.flowRedirect ? $t('admin.crm.modal.message.errors.notJsonRequestBody') : ''"
                :type="{'is-danger': !validationOptions.flowRedirect}">
                <LInput v-model="flowRedirect">
                    <template #default="{ value, update }">
                        <b-input
                            ref="flowRedirect"
                            :value="value"
                            type="textarea"
                            @input="update">
                        </b-input>
                    </template>
                </LInput>
            </b-field>
    
            <b-taglist>
                <b-button
                    :key="macros"
                    v-for="macros in flowRedirectMacrosList"
                    class="tag"
                    size="is-small"
                    type="is-info"
                    @click="addMacro(`{${macros}}`, 'flowRedirect', 'textarea')">
                    {{ "{" + macros + "}" }}
                </b-button>
            </b-taglist>
        </template>
    </CustomCollapse>
</template>

<script>
  import CustomCollapse from "@/components/Common/Controls/CustomCollapse.vue";
  import { CRM } from "@core/mixins";
  import { mapFields } from "@core/plugins/mapFields";
  import { UPDATE_FLOW_REDIRECT } from "@core/store/action-constants";
  import { mapState } from "vuex";
  
  export default {
    name: "CRMCreateFlowRedirect",
    mixins: [CRM],
    components: {
      CustomCollapse
    },
    
    data () {
      return {
        validationOptions: {
          flowRedirect: true
        }
      };
    },
    
    computed: {
      ...mapState("admin/crm/crmCreateModal", {
        flowRedirectMacrosList: state => state.flowRedirectMacrosList.data
      }),
      
      ...mapFields("admin/crm/crmCreateModal", {
        fields: ["flowRedirect"],
        base: "options",
        action: UPDATE_FLOW_REDIRECT
      })
    },
    
    methods: {
      isValid () {
        return Object.values(this.validationOptions).every(value => value === true);
      }
    },
    
    watch: {
      flowRedirect (value) {
        this.$set(this.validationOptions, "flowRedirect", this.isJsonString(value));
      }
    }
  };
</script>

<style scoped>

</style>
