<template>
    <CustomCollapse open>
        <template #title>
            <h5 class="subtitle is-4 my-2 card-header-title p-0">
                {{ $t("admin.crm.modal.labels.postbackDetail.postbackConfiguration") }}
            </h5>
        </template>

        <template #icon="{ trigger }">
            <a class="card-header-icon my-2 py-2 pl-4 pr-0">
                <b-icon :icon="!trigger.open ? 'angle-down' : 'angle-up'"></b-icon>
            </a>
        </template>
        <template #content>
            <b-field :label="$t('admin.crm.modal.labels.postbackDetail.strategy')">
                <b-radio-button
                    :key="strategy.value"
                    v-for="strategy in strategyList.items"
                    v-model="postbackDetail.strategy"
                    :native-value="strategy.value"
                    type="is-info is-light">
                    <span>{{ strategy.label }}</span>
                </b-radio-button>
            </b-field>
            <b-field
                :label="$t('admin.crm.modal.labels.postbackDetail.accept')"
                :message="!validationOptions.accept && !accept.length ? $t('admin.crm.modal.message.errors.emptyField') : ''"
                type="is-danger">
                <Select
                    v-model="accept"
                    :create-option="name => ({ name, id: name })"
                    :get-data-vuex="() => getData(accept)"
                    :seed="JSON.stringify(accept)"
                    hide-null
                    multiple
                    no-drop
                    taggable>
                </Select>
            </b-field>
    
            <b-field
                :label="$t('admin.crm.modal.labels.postbackDetail.spam')"
                :message="!validationOptions.spam && !spam.length ? $t('admin.crm.modal.message.errors.emptyField') : ''"
                type="is-danger">
                <Select
                    v-model="spam"
                    :create-option="name => ({ name, id: name })"
                    :get-data-vuex="() => getData(spam)"
                    :seed="JSON.stringify(spam)"
                    hide-null
                    multiple
                    no-drop
                    taggable>
                </Select>
            </b-field>
    
            <b-field
                :label="$t('admin.crm.modal.labels.postbackDetail.cancel')"
                :message="!validationOptions.cancel && !cancel.length ? $t('admin.crm.modal.message.errors.emptyField') : ''"
                type="is-danger">
                <Select
                    v-model="cancel"
                    :create-option="name => ({ name, id: name })"
                    :get-data-vuex="() => getData(cancel)"
                    :seed="JSON.stringify(cancel)"
                    hide-null
                    multiple
                    no-drop
                    taggable>
                </Select>
            </b-field>

            <b-field :label="$t('admin.crm.modal.labels.postbackDetail.redemption')">
                <Select
                    v-model="redemption"
                    :create-option="name => ({ name, id: name })"
                    :get-data-vuex="() => getData(redemption)"
                    :seed="JSON.stringify(redemption)"
                    hide-null
                    multiple
                    no-drop
                    taggable>
                </Select>
            </b-field>

            <b-field :label="$t('admin.crm.modal.labels.postbackDetail.return')">
                <Select
                    v-model="returnField"
                    :create-option="name => ({ name, id: name })"
                    :get-data-vuex="() => getData(returnField)"
                    :seed="JSON.stringify(returnField)"
                    hide-null
                    multiple
                    no-drop
                    taggable>
                </Select>
            </b-field>
        </template>
    </CustomCollapse>
</template>

<script>
  import CustomCollapse from "@/components/Common/Controls/CustomCollapse.vue";
  import { UPDATE_MODAL_OPTIONS, UPDATE_POSTBACK_STATUS_MAP } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapState } from "vuex";
  import Select from "@/components/Common/Select/Select.vue";
  
  export default {
    name: "CRMCreatePostbackConfiguration",
    components: {
      Select,
      CustomCollapse
    },

    data () {
      return {
        validationOptions: {
          accept: true,
          spam: true,
          cancel: true
        }
      };
    },

    computed: {
      ...mapState("admin/crm/crmCreateModal",{
        strategyList: state => state.strategyList.data
      }),

      ...mapFields("admin/crm/crmCreateModal", {
        fields: ["postbackDetail"],
        base: "options",
        action: UPDATE_MODAL_OPTIONS
      }),

      ...mapFields("admin/crm/crmCreateModal", {
        fields: ["accept", "spam", "cancel", "redemption", "returnField"],
        base: "options.postbackDetail.statusMap",
        action: UPDATE_POSTBACK_STATUS_MAP
      })
    },

    methods: {
      isValid () {
        for (const key in this.validationOptions) {
          this.$set(this.validationOptions, key, !!this[key].length);
        }
        return Object.values(this.validationOptions).every(value => value === true);
      },

      getData (value) {
        const items = value.map(name => ({ name, id: name }));
        return { items, count: value.length };
      }
    }
  };
</script>

<style scoped>

</style>
