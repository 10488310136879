<template>
    <CustomCollapse>
        <template #title>
            <h5 class="subtitle is-4 my-2 card-header-title p-0">
                {{ $t("admin.crm.modal.labels.FailRule.title") }}
            </h5>
        </template>

        <template #icon="{ trigger }">
            <a class="card-header-icon my-2 py-2 pl-4 pr-0">
                <b-icon :icon="!trigger.open ? 'angle-down' : 'angle-up'"></b-icon>
            </a>
        </template>

        <template #content>
            <form @submit.prevent.stop="submit">
                <div class="columns is-multiline">
                    <div class="column">
                        <b-field :label="$t('admin.crm.modal.labels.FailRule.typeError.title')">
                            <b-radio-button
                                :key="error.value"
                                v-for="error in typeErrorList"
                                v-model="failRuleType"
                                :native-value="error.value"
                                :style="{ width: `${ 100 / typeErrorList.length }%` }"
                                type="is-info is-light">
                                {{ error.label }}
                            </b-radio-button>
                        </b-field>
                    </div>

                    <div class="column">
                        <b-field :label="$t('admin.crm.modal.labels.FailRule.failRuleComparison.title')">
                            <b-radio-button
                                :key="comparison.value"
                                v-for="comparison in failRuleComparisonList"
                                v-model="failRuleComparison"
                                :native-value="comparison.value"
                                :style="{ width: `${ 100 / failRuleComparisonList.length }%` }"
                                type="is-info is-light">
                                {{ comparison.label }}
                            </b-radio-button>
                        </b-field>
                    </div>
                </div>

                <div class="columns is-multiline">
                    <div class="column">
                        <b-field :label="$t('admin.crm.modal.labels.FailRule.verificationText')">
                            <LInput v-model="text"></LInput>
                        </b-field>
                    </div>

                    <div class="column">
                        <b-field :label="$t('admin.crm.modal.labels.FailRule.spamReason')">
                            <ReasonsSelect v-model="spamReason"></ReasonsSelect>
                        </b-field>
                    </div>
                </div>

                <div
                    v-if="failRuleType === 'JSON'"
                    class="columns is-multiline">
                    <div class="column">
                        <b-field :label="$t('admin.crm.modal.labels.FailRule.xpath')">
                            <LInput v-model="xpath"></LInput>
                        </b-field>
                    </div>
                </div>

                <div class="columns is-multiline">
                    <div class="column is-10">
                        <b-button
                            :loading="isLoading"
                            :disabled="hasDisabledButton"
                            class="is-fullwidth is-success is-light"
                            native-type="submit">
                            {{ $t(`common.buttons.add`) }}
                        </b-button>
                    </div>
                    <div class="column is-2">
                        <b-button
                            :loading="isLoading"
                            :disabled="data.length === 0"
                            class="is-fullwidth is-info is-light"
                            icon-left="user-shield"
                            @click="openFailRoleModal">
                            {{ data.length }}
                        </b-button>
                    </div>
                </div>
            </form>
        </template>
    </CustomCollapse>
</template>

<script>
  import CustomCollapse from "@/components/Common/Controls/CustomCollapse.vue";
  import ReasonsSelect from "@/components/Common/Select/ReasonsSelect.vue";
  import {
    CREATE_RULES,
    UPDATE_MODAL_ACTIVE,
    UPDATE_FAIL_RULE_OPTIONS
  } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapState } from "vuex";
  import { actionWithToast } from "@/helpers/actionWithToast";

  export default {
    name: "CRMFailRule",
    components: {
      ReasonsSelect,
      CustomCollapse
    },

    computed: {
      ...mapState("admin/crm/crmCreateModal/crmFailRule", {
        isModalActive: ({ isModalActive }) => isModalActive,
        data: ({ data }) => data?.items ?? []
      }),

      ...mapFields("admin/crm/crmCreateModal/crmFailRule", {
        fields: ["failRuleType", "failRuleComparison", "text", "spamReason", "xpath"],
        base: "options",
        action: UPDATE_FAIL_RULE_OPTIONS
      }),

      hasDisabledButton () {
        const { failRuleType, failRuleComparison, text, spamReason, xpath } = this;
        const isJSONType = failRuleType === "JSON";
        const isXPathValid = isJSONType ? !!xpath : !xpath;
        const isSpamReasonValid = !!(spamReason || spamReason <= 0 && spamReason !== null);
        return !(failRuleType && failRuleComparison && text && isSpamReasonValid && isXPathValid);
      },

      isLoading () {
        return this.$wait(`admin/crm/crmCreateModal/crmFailRule/${ CREATE_RULES }`);
      },

      typeErrorList () {
        return [{ label: "JSON", value: "JSON" }, { label: this.$t("admin.crm.modal.labels.FailRule.typeError.OTHER"), value: "OTHER" }];
      },

      failRuleComparisonList () {
        return [
          { label: this.$t("admin.crm.modal.labels.FailRule.failRuleComparison.contain"), value: "CONTAIN" },
          { label: this.$t("admin.crm.modal.labels.FailRule.failRuleComparison.equal"), value: "EQUAL" }
        ];
      }
    },

    methods: {
      ...mapActions("admin/crm/crmCreateModal/crmFailRule", {
        SET_EMPTY_OPTIONS: "SET_EMPTY_OPTIONS",
        UPDATE_MODAL_ACTIVE,
        CREATE_RULES
      }),

      ...mapActions("admin/crm/crmCreateModal", {
        UPDATE_CRM_MODAL_ACTIVE: UPDATE_MODAL_ACTIVE
      }),

      openFailRoleModal () {
        this.UPDATE_MODAL_ACTIVE(true);
      },

      async submit () {
        await actionWithToast(
          this.CREATE_RULES(),
          "admin.crm.modal.labels.FailRule.messages.add"
        );
        this.SET_EMPTY_OPTIONS();
      }
    },

    watch: {
      failRuleType () {
        this.xpath = null;
      },

      isModalActive (value) {
        this.UPDATE_CRM_MODAL_ACTIVE(!value);
      }
    }
  };
</script>

<style scoped lang="scss">
</style>
