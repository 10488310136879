var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalBox',{attrs:{"is-active":_vm.isModalActive},on:{"update:isActive":function($event){_vm.isModalActive=$event},"update:is-active":function($event){_vm.isModalActive=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h5',{staticClass:"title is-5"},[_vm._v(" "+_vm._s(_vm.$t("admin.crm.modal.labels.FailRule.table.title"))+" ")])]},proxy:true}])},[_c('b-table',{attrs:{"data":_vm.data}},[_c('b-table-column',{attrs:{"centered":"","label":_vm.$t("admin.crm.modal.labels.FailRule.table.labels.failRuleType")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$t(("admin.crm.modal.labels.FailRule.typeError." + (row.failRuleType))))+" ")]}}])}),_c('b-table-column',{attrs:{"centered":"","label":_vm.$t("admin.crm.modal.labels.FailRule.table.labels.failRuleComparison")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$t(("admin.crm.modal.labels.FailRule.failRuleComparison." + (row.failRuleComparison.toLowerCase()))))+" ")]}}])}),_c('b-table-column',{attrs:{"centered":"","label":_vm.$t("admin.crm.modal.labels.FailRule.table.labels.text")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.text)+" ")]}}])}),_c('b-table-column',{attrs:{"centered":"","label":_vm.$t("admin.crm.modal.labels.FailRule.table.labels.spamReason")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$t(("common.entity.cancelReason." + (row.spamReason))))+" ")]}}])}),_c('b-table-column',{attrs:{"centered":"","label":_vm.$t("admin.crm.modal.labels.FailRule.table.labels.xpath")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatEmptyString(row.xpath))+" ")]}}])}),_c('b-table-column',{attrs:{"centered":"","label":_vm.$t("admin.crm.modal.labels.FailRule.table.labels.createdAt")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.moment(row.createdAt, "DD.MM.YYYY"))+" ")]}}])}),_c('b-table-column',{attrs:{"width":"10%","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var id = ref.row.id;
return [_c('LTooltip',{attrs:{"label":_vm.$t('common.buttons.delete'),"type":"is-danger"}},[_c('b-button',{attrs:{"icon-left":"trash-alt","loading":_vm.isLoading && _vm.localId === id,"rounded":"","size":"is-small","type":"is-danger is-light"},on:{"click":function($event){return _vm.deleteFailRule(id)}}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }