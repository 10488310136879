/**
 * @param { Function } fn
 * @return { function(*=, *=): * }
 */
function normalizeNamespace (fn) {
  return (namespace, map) => {
    if (typeof namespace !== "string") {
      map = namespace;
      namespace = "";
    } else if (namespace.charAt(namespace.length - 1) !== "/") {
      namespace += "/";
    }
    return fn(namespace, map);
  };
}

/**
 * @param { Object } state
 * @param { String } base
 * @return { Object }
 */
function normalizeBase (state, base) {
  return base
    .replace(/\./g, "/")
    .split("/")
    .filter(Boolean)
    .reduce((object, level) => object[level], state);
}

/**
 *
 * @type { function(*=, *=): * }
 */
export const mapFields = normalizeNamespace((namespace, options) => {
  const object = {};
  
  for (let x = 0; x < options.fields.length; x++) {
    const field = options.fields[x];
    
    object[field] = {
      get () {
        const base = options.base ?? "";
        const normalizedBase = normalizeBase(this.$store.state, namespace + base);
        
        return normalizedBase[field];
      },
      
      set (value) {
        if (options.action) {
          this.$store.dispatch(namespace + options.action, { [field]: value }).then();
        }
        
        if (options.mutation) {
          // eslint-disable-next-line no-console
          console.warn("mutation param is deprecated, use action instead");
          this.$store.commit(namespace + options.mutation, { [field]: value });
        }
      }
    };
  }
  
  return object;
});
