<template>
    <ModalBox :is-active.sync="isModalActive">
        <template #header>
            <h5 class="title is-5">
                {{ $t(`admin.crm.modal.labels.FailRule.table.title`) }}
            </h5>
        </template>

        <b-table :data="data">
            <b-table-column
                v-slot="{ row }"
                centered
                :label="$t(`admin.crm.modal.labels.FailRule.table.labels.failRuleType`)">
                {{ $t(`admin.crm.modal.labels.FailRule.typeError.${ row.failRuleType }`) }}
            </b-table-column>

            <b-table-column
                v-slot="{ row }"
                centered
                :label="$t(`admin.crm.modal.labels.FailRule.table.labels.failRuleComparison`)">
                {{ $t(`admin.crm.modal.labels.FailRule.failRuleComparison.${ row.failRuleComparison.toLowerCase() }`) }}
            </b-table-column>

            <b-table-column
                v-slot="{ row }"
                centered
                :label="$t(`admin.crm.modal.labels.FailRule.table.labels.text`)">
                {{ row.text }}
            </b-table-column>

            <b-table-column
                v-slot="{ row }"
                centered
                :label="$t(`admin.crm.modal.labels.FailRule.table.labels.spamReason`)">
                {{ $t(`common.entity.cancelReason.${ row.spamReason }`) }}
            </b-table-column>

            <b-table-column
                v-slot="{ row }"
                centered
                :label="$t(`admin.crm.modal.labels.FailRule.table.labels.xpath`)">
                {{ formatEmptyString(row.xpath) }}
            </b-table-column>

            <b-table-column
                v-slot="{ row }"
                centered
                :label="$t(`admin.crm.modal.labels.FailRule.table.labels.createdAt`)">
                {{ moment(row.createdAt, "DD.MM.YYYY") }}
            </b-table-column>

            <b-table-column
                v-slot="{ row: { id } }"
                width="10%"
                centered>
                <LTooltip
                    :label="$t('common.buttons.delete')"
                    type="is-danger">
                    <b-button
                        icon-left="trash-alt"
                        :loading="isLoading && localId === id"
                        rounded
                        size="is-small"
                        type="is-danger is-light"
                        @click="deleteFailRule(id)">
                    </b-button>
                </LTooltip>
            </b-table-column>
        </b-table>
    </ModalBox>
</template>

<script>
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import { DELETE_ROLE, UPDATE_MODAL_ACTIVE } from "@core/store/action-constants";
  import { actionWithToast } from "@/helpers/actionWithToast";
  import { mapActions, mapState } from "vuex";
  import { formatEmptyString, moment } from "@core/filters";

  export default {
    name: "CRMFailRuleDataModal",

    components: {
      ModalBox
    },

    data () {
      return {
        localId: null
      };
    },

    computed: {
      ...mapState("admin/crm/crmCreateModal/crmFailRule", {
        _isModalActive: ({ isModalActive }) => isModalActive,
        data: ({ data }) => data?.items || []
      }),

      isLoading () {
        return this.$wait(`admin/crm/crmCreateModal/crmFailRule/${ DELETE_ROLE }`);
      },

      isModalActive: {
        get () {
          return this._isModalActive;
        },

        set (value) {
          this.UPDATE_MODAL_ACTIVE(value);
          this.UPDATE_CRM_MODAL_ACTIVE(!value);
        }
      }
    },

    methods: {
      formatEmptyString,
      moment,

      ...mapActions("admin/crm/crmCreateModal/crmFailRule", {
        UPDATE_MODAL_ACTIVE,
        DELETE_ROLE
      }),

      ...mapActions("admin/crm/crmCreateModal", {
        UPDATE_CRM_MODAL_ACTIVE: UPDATE_MODAL_ACTIVE
      }),

      async deleteFailRule (id) {
        try {
          this.localId = id;
          await actionWithToast(
            this.DELETE_ROLE(id),
            "admin.crm.modal.labels.FailRule.messages.remove"
          );
        } finally {
          this.localId = null;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
    .table {
      th {
        font-size: 14px !important;
      }
    }

    .animation-content {
      width: 100%;
      max-width: 750px !important;

      .modal-card {
        width: 100%;
        max-width: none;
      }
    }
  }
</style>
