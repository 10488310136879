<template>
    <CRM></CRM>
</template>

<script>
  import CRM from "@/components/Admin/CRM/CRM";

  export default {
    name: "CRMView",
    components: { CRM }
  };
</script>

<style scoped>

</style>