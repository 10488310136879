<template>
    <CustomCollapse open>
        <template #title>
            <h5 class="subtitle is-4 my-2 card-header-title p-0">
                {{ $t("admin.crm.modal.labels.integrationDetail.request") }}
            </h5>
        </template>

        <template #icon="{ trigger }">
            <a class="card-header-icon my-2 py-2 pl-4 pr-0">
                <b-icon :icon="!trigger.open ? 'angle-down' : 'angle-up'"></b-icon>
            </a>
        </template>

        <template #content>
            <b-field
                expanded
                label="URL">
                <LInput v-model="requestUrl">
                    <template #default="{ value, update }">
                        <b-input
                            ref="requestUrl"
                            :disabled="isWithoutRequest"
                            :value="value"
                            placeholder="https://"
                            required
                            type="url"
                            @input="update">
                        </b-input>
                    </template>
                </LInput>
            </b-field>
    
            <b-taglist>
                <b-button
                    :key="macros"
                    v-for="macros in macrosList"
                    :disabled="isWithoutRequest"
                    class="tag"
                    size="is-small"
                    type="is-info"
                    @click="addMacro(`{${macros}}`)">
                    {{ "{" + macros + "}" }}
                </b-button>
            </b-taglist>
    
    
            <b-field
                :label="$t('admin.crm.modal.labels.integrationDetail.requestHeaders')"
                :message="!validationOptions.requestHeaders ? $t('admin.crm.modal.message.errors.notJsonRequestBody') : ''"
                :type="{'is-danger': !validationOptions.requestHeaders}">
                <LInput v-model="requestHeaders">
                    <template #default="{ value, update }">
                        <b-input
                            ref="requestHeaders"
                            :disabled="isWithoutRequest"
                            :value="value"
                            type="textarea"
                            @input="update">
                        </b-input>
                    </template>
                </LInput>
            </b-field>
    
            <b-taglist>
                <b-button
                    :key="macros"
                    v-for="macros in macrosList"
                    :disabled="isWithoutRequest"
                    class="tag"
                    size="is-small"
                    type="is-info"
                    @click="addMacro(`{${macros}}`, 'requestHeaders', 'textarea')">
                    {{ "{" + macros + "}" }}
                </b-button>
            </b-taglist>

            <div class="columns is-multiline">
                <div class="column">
                    <b-field :label="$t('admin.crm.modal.labels.integrationDetail.requestMethod')">
                        <b-radio-button
                            :key="method.value"
                            v-for="method in methodsList.items"
                            v-model="requestMethod"
                            :disabled="isWithoutRequest"
                            :native-value="method.value"
                            type="is-info is-light">
                            <span>{{ method.label }}</span>
                        </b-radio-button>
                    </b-field>
                </div>

                <div class="column">
                    <b-field :label="$t('admin.crm.modal.labels.integrationDetail.requestBodyType')">
                        <b-radio-button
                            :key="type.value"
                            v-for="type in requestTypesList.items"
                            v-model="requestBodyType"
                            :disabled="!requestMethod || requestMethod === 'GET' || isWithoutRequest"
                            :native-value="type.value"
                            type="is-info is-light">
                            <span v-t="type.label"></span>
                        </b-radio-button>
                    </b-field>
                </div>
            </div>
    
            <b-field
                :label="$t('admin.crm.modal.labels.integrationDetail.requestBody')"
                :message="!validationOptions.requestBody ? $t('admin.crm.modal.message.errors.notJsonRequestBody') : ''"
                :type="{'is-danger': !validationOptions.requestBody}">
                <LInput v-model="_requestBody">
                    <template #default="{ value, update }">
                        <b-input
                            ref="requestBody"
                            :disabled="requestBodyType === 'EMPTY' || isWithoutRequest"
                            :required="requestBodyType !== 'EMPTY'"
                            :value="value"
                            type="textarea"
                            @input="update">
                        </b-input>
                    </template>
                </LInput>
            </b-field>
    
            <b-taglist>
                <b-button
                    :key="macros"
                    v-for="macros in macrosList"
                    :disabled="requestBodyType === 'EMPTY' || isWithoutRequest"
                    class="tag"
                    size="is-small"
                    type="is-info"
                    @click="addMacro(`{${macros}}`, 'requestBody', 'textarea')">
                    {{ "{" + macros + "}" }}
                </b-button>
            </b-taglist>
        </template>
    </CustomCollapse>
</template>

<script>
  import CustomCollapse from "@/components/Common/Controls/CustomCollapse.vue";
  import { CRM } from "@core/mixins";
  import { UPDATE_CUSTOM_MACROS, UPDATE_INTEGRATION_DETAILS } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapState } from "vuex";
  
  export default {
    name: "CRMCreateRequest",
    mixins: [CRM],
    components: {
      CustomCollapse
    },
  
    data () {
      return {
        localRequestBody: null,
        validationOptions: {
          requestBody: true,
          requestHeaders: true
        }
      };
    },

    computed: {
      ...mapState("admin/crm/crmCreateModal",{
        options: state => state.options,
        methodsList: state => state.methodsList.data,
        requestTypesList: state => state.requestTypesList.data,
        macrosList: state => state.macrosList.data,
        isWithoutRequest: state => state.isWithoutRequest
      }),

      _requestBody: {
        get () {
          if (this.requestBodyType === "EMPTY") {
            return this.localRequestBody;
          } else {
            return this.requestBody;
          }
        },
        set (value) {
          this.requestBody = value;
        }
      },

      ...mapFields("admin/crm/crmCreateModal", {
        fields: ["requestMethod", "requestBodyType"],
        base: "options.integrationDetail",
        action: UPDATE_INTEGRATION_DETAILS
      }),

      ...mapFields("admin/crm/crmCreateModal", {
        fields: ["requestUrl", "requestBody", "requestHeaders"],
        base: "options.integrationDetail",
        action: UPDATE_CUSTOM_MACROS
      }),

      isRequestTypeDisabled () {
        return !this.integrationDetail.requestMethod || this.integrationDetail.requestMethod === "GET";
      }
    },

    methods: {
      ...mapActions("admin/crm/crmCreateModal", {
        UPDATE_CUSTOM_MACROS
      }),

      isValid () {
        return Object.values(this.validationOptions).every(value => value === true);
      }
    },

    watch: {
      requestMethod (value) {
        if (value === "GET") {
          this.requestBodyType = "EMPTY";
        }
      },

      requestBodyType (value, oldValue) {
        if (value === "EMPTY") {
          this.localRequestBody = this.requestBody;
          this.requestBody = null;
        } else if (oldValue === "EMPTY" && !this.requestBody) {
          this.requestBody = this.localRequestBody;
        }
      },

      requestBody (value) {
        this.$set(this.validationOptions, "requestBody", this.isJsonString(value));
      },

      requestHeaders (value) {
        this.$set(this.validationOptions, "requestHeaders", this.isJsonString(value));
      }
    }
  };
</script>

<style scoped>
</style>
