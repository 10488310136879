<template functional>
    <b-collapse
        :open="props.open"
        class="card">
        <template #trigger="trigger">
            <slot name="trigger">
                <div class="card-header">
                    <slot name="title">
                        <h2 class="card-header-title p-2">
                            <slot name="label">
                                {{ props.label }}
                            </slot>
                        </h2>
                    </slot>
                    <slot
                        :trigger="trigger"
                        name="icon">
                        <a class="card-header-icon p-2">
                            <b-icon :icon="!trigger.open ? 'angle-down' : 'angle-up'"></b-icon>
                        </a>
                    </slot>
                </div>
            </slot>
        </template>
        <div class="card-content">
            <div class="content">
                <slot name="content"></slot>
            </div>
        </div>
    </b-collapse>
</template>

<script>
  export default {
    name: "CustomCollapse",
    props: {
      label: {
        type: String,
        default: null
      },

      open: {
        type: Boolean,
        default: false
      }
    }
  };
</script>

<style scoped>
    .card:not(:last-child) {
        margin-bottom: 0.5rem;
    }
</style>
