<template>
    <b-field
        v-show="customMacros.length"
        :label="$t('admin.crm.modal.labels.integrationDetail.customMacros')">
        <div class="columns is-multiline">
            <div
                :key="macros"
                v-for="macros in customMacros"
                class="column is-6 py-2">
                <b-field :label="macros">
                    <LInput v-model="customFields[macros]">
                    </LInput>
                </b-field>
            </div>
        </div>
    </b-field>
</template>

<script>
  import { mapState } from "vuex";
  
  export default {
    name: "CRMCustomMacros",
    computed: {
      ...mapState("admin/crm/crmCreateModal", {
        customMacros: state => state.customMacros.customMacros,
        _customFields: state => state.customMacros.customFields
      }),
      
      customFields: {
        get () {
          return this._customFields;
        },
        set (value) {
          this.UPDATE_CUSTOM_MACROS({ customFields: value });
        }
      }
    }
  };
</script>

<style scoped>

</style>
