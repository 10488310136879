<template>
    <Component
        :is="componentInstance"
        :value="value"
        v-bind="$attrs"
        :get-data-vuex="getReasons"
        class="white"
        field="label"
        prop="value"
        marquee
        :placeholder="placeholder"
        @input="$emit('input', $event)">
    </Component>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import { SET_EMPTY, GET_REASONS } from "@core/store/action-constants";
  import { filteredDataBySearch } from "@core/helpers/filteredDataBySearch";

  export default {
    name: "ReasonsSelect",

    props: {
      value: {
        type: null,
        default: null
      },

      placeholder: {
        type: String,
        default: null
      },

      lazy: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapState("reasonsList", {
        reasons: ({ data }) => data
      }),

      componentInstance () {
        const name = this.lazy ? "LazySelect" : "Select";
        return () => import(`@/components/Common/Select/${ name }`);
      }
    },

    methods: {
      ...mapActions("reasonsList", {
        GET_REASONS,
        SET_EMPTY
      }),

      getReasons (label = "") {
        this.GET_REASONS();
        const { reasons: items } = this;

        if (label) {
          return filteredDataBySearch(items, label, ["label"]);
        }

        return {
          items,
          count: items.length
        };
      }
    },

    beforeDestroy () {
      this.SET_EMPTY();
    }
  };
</script>

<style scoped>

</style>